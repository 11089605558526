export default [
    { name: 'Germany', prefix: '+49', country: 'DE', height: '12', backgroundPosition: '-1269' },
    { name: 'France', prefix: '+33', country: 'FR', height: '14', backgroundPosition: '-1731' },
    { name: 'United Kingdom', prefix: '+44', country: 'GB', height: '10', backgroundPosition: '-1775' },
    { name: 'Afghanistan', prefix: '+93', country: 'AF', height: '14', backgroundPosition: '-66' },
    { name: 'Åland Islands', prefix: '+358', country: 'AX', height: '13', backgroundPosition: '-330' },
    { name: 'Albania', prefix: '+355', country: 'AL', height: '15', backgroundPosition: '-132' },
    { name: 'Algeria', prefix: '+213', country: 'DZ', height: '14', backgroundPosition: '-1401' },
    { name: 'American Samoa', prefix: '+1684', country: 'AS', height: '10', backgroundPosition: '-242' },
    { name: 'Andorra', prefix: '+376', country: 'AD', height: '14', backgroundPosition: '-22' },
    { name: 'Angola', prefix: '+244', country: 'AO', height: '14', backgroundPosition: '-176' },
    { name: 'Anguilla', prefix: '+1264', country: 'AI', height: '10', backgroundPosition: '-110' },
    { name: 'Antarctica', prefix: '+672', country: 'AQ', height: '14', backgroundPosition: '-198' },
    { name: 'Antigua and Barbuda', prefix: '+1268', country: 'AG', height: '14', backgroundPosition: '-88' },
    { name: 'Argentina', prefix: '+54', country: 'AR', height: '13', backgroundPosition: '-220' },
    { name: 'Armenia', prefix: '+374', country: 'AM', height: '10', backgroundPosition: '-154' },
    { name: 'Aruba', prefix: '+297', country: 'AW', height: '14', backgroundPosition: '-308' },
    { name: 'Australia', prefix: '+61', country: 'AU', height: '10', backgroundPosition: '-286' },
    { name: 'Austria', prefix: '+43', country: 'AT', height: '14', backgroundPosition: '-264' },
    { name: 'Azerbaijan', prefix: '+994', country: 'AZ', height: '10', backgroundPosition: '-352' },
    { name: 'Bahamas', prefix: '+1242', country: 'BS', height: '10', backgroundPosition: '-702' },
    { name: 'Bahrain', prefix: '+973', country: 'BH', height: '12', backgroundPosition: '-504' },
    { name: 'Bangladesh', prefix: '+880', country: 'BD', height: '12', backgroundPosition: '-418' },
    { name: 'Barbados', prefix: '+1246', country: 'BB', height: '14', backgroundPosition: '-396' },
    { name: 'Belarus', prefix: '+375', country: 'BY', height: '10', backgroundPosition: '-790' },
    { name: 'Belgium', prefix: '+32', country: 'BE', height: '15', backgroundPosition: '-440' },
    { name: 'Belize', prefix: '+501', country: 'BZ', height: '14', backgroundPosition: '-812' },
    { name: 'Benin', prefix: '+229', country: 'BJ', height: '14', backgroundPosition: '-548' },
    { name: 'Bermuda', prefix: '+1441', country: 'BM', height: '10', backgroundPosition: '-592' },
    { name: 'Bhutan', prefix: '+975', country: 'BT', height: '14', backgroundPosition: '-724' },
    {
        name: 'Bolivia, Plurinational State of bolivia',
        prefix: '+591',
        country: 'BO',
        height: '14',
        backgroundPosition: '-636'
    },
    { name: 'Bosnia and Herzegovina', prefix: '+387', country: 'BA', height: '10', backgroundPosition: '-374' },
    { name: 'Botswana', prefix: '+267', country: 'BW', height: '14', backgroundPosition: '-768' },
    { name: 'Bouvet Island', prefix: '+47', country: 'BV', height: '15', backgroundPosition: '-746' },
    { name: 'Brazil', prefix: '+55', country: 'BR', height: '14', backgroundPosition: '-680' },
    {
        name: 'British Indian Ocean Territory',
        prefix: '+246',
        country: 'IO',
        height: '10',
        backgroundPosition: '-2435'
    },
    { name: 'Brunei Darussalam', prefix: '+673', country: 'BN', height: '10', backgroundPosition: '-614' },
    { name: 'Bulgaria', prefix: '+359', country: 'BG', height: '12', backgroundPosition: '-482' },
    { name: 'Burkina Faso', prefix: '+226', country: 'BF', height: '14', backgroundPosition: '-460' },
    { name: 'Burundi', prefix: '+257', country: 'BI', height: '12', backgroundPosition: '-526' },
    { name: 'Cambodia', prefix: '+855', country: 'KH', height: '13', backgroundPosition: '-2677' },
    { name: 'Cameroon', prefix: '+237', country: 'CM', height: '14', backgroundPosition: '-1027' },
    { name: 'Canada', prefix: '+1', country: 'CA', height: '10', backgroundPosition: '-834' },
    { name: 'Cape Verde', prefix: '+238', country: 'CV', height: '12', backgroundPosition: '-1159' },
    { name: 'Cayman Islands', prefix: '+ 345', country: 'KY', height: '10', backgroundPosition: '-2831' },
    { name: 'Central African Republic', prefix: '+236', country: 'CF', height: '14', backgroundPosition: '-900' },
    { name: 'Chad', prefix: '+235', country: 'TD', height: '14', backgroundPosition: '-4845' },
    { name: 'Chile', prefix: '+56', country: 'CL', height: '14', backgroundPosition: '-1005' },
    { name: 'China', prefix: '+86', country: 'CN', height: '14', backgroundPosition: '-1049' },
    { name: 'Christmas Island', prefix: '+61', country: 'CX', height: '10', backgroundPosition: '-1203' },
    { name: 'Cocos (Keeling) Islands', prefix: '+61', country: 'CC', height: '10', backgroundPosition: '-856' },
    { name: 'Colombia', prefix: '+57', country: 'CO', height: '14', backgroundPosition: '-1071' },
    { name: 'Comoros', prefix: '+269', country: 'KM', height: '12', backgroundPosition: '-2721' },
    { name: 'Congo', prefix: '+242', country: 'CG', height: '14', backgroundPosition: '-922' },
    {
        name: 'Congo, The Democratic Republic of the Congo',
        prefix: '+243',
        country: 'CD',
        height: '15',
        backgroundPosition: '-878'
    },
    { name: 'Cook Islands', prefix: '+682', country: 'CK', height: '10', backgroundPosition: '-983' },
    { name: 'Costa Rica', prefix: '+506', country: 'CR', height: '12', backgroundPosition: '-1115' },
    { name: "Cote d'Ivoire", prefix: '+225', country: 'CI', height: '14', backgroundPosition: '-961' },
    { name: 'Croatia', prefix: '+385', country: 'HR', height: '10', backgroundPosition: '-2237' },
    { name: 'Cuba', prefix: '+53', country: 'CU', height: '10', backgroundPosition: '-1137' },
    { name: 'Cyprus', prefix: '+357', country: 'CY', height: '14', backgroundPosition: '-1225' },
    { name: 'Czech Republic', prefix: '+420', country: 'CZ', height: '14', backgroundPosition: '-1247' },
    { name: 'Denmark', prefix: '+45', country: 'DK', height: '15', backgroundPosition: '-1335' },
    { name: 'Djibouti', prefix: '+253', country: 'DJ', height: '14', backgroundPosition: '-1313' },
    { name: 'Dominica', prefix: '+1767', country: 'DM', height: '10', backgroundPosition: '-1357' },
    { name: 'Dominican Republic', prefix: '+1849', country: 'DO', height: '14', backgroundPosition: '-1379' },
    { name: 'Ecuador', prefix: '+593', country: 'EC', height: '14', backgroundPosition: '-1445' },
    { name: 'Egypt', prefix: '+20', country: 'EG', height: '14', backgroundPosition: '-1489' },
    { name: 'El Salvador', prefix: '+503', country: 'SV', height: '12', backgroundPosition: '-4713' },
    { name: 'Equatorial Guinea', prefix: '+240', country: 'GQ', height: '14', backgroundPosition: '-2017' },
    { name: 'Eritrea', prefix: '+291', country: 'ER', height: '10', backgroundPosition: '-1533' },
    { name: 'Estonia', prefix: '+372', country: 'EE', height: '13', backgroundPosition: '-1467' },
    { name: 'Ethiopia', prefix: '+251', country: 'ET', height: '10', backgroundPosition: '-1577' },
    { name: 'Falkland Islands (Malvinas)', prefix: '+500', country: 'FK', height: '10', backgroundPosition: '-1665' },
    { name: 'Faroe Islands', prefix: '+298', country: 'FO', height: '15', backgroundPosition: '-1709' },
    { name: 'Fiji', prefix: '+679', country: 'FJ', height: '10', backgroundPosition: '-1643' },
    { name: 'Finland', prefix: '+358', country: 'FI', height: '12', backgroundPosition: '-1621' },
    { name: 'French Guiana', prefix: '+594', country: 'GF', height: '14', backgroundPosition: '-1841' },
    { name: 'French Polynesia', prefix: '+689', country: 'PF', height: '14', backgroundPosition: '-3943' },
    { name: 'French Southern Territories', prefix: '+262', country: 'TF', height: '14', backgroundPosition: '-4867' },
    { name: 'Gabon', prefix: '+241', country: 'GA', height: '15', backgroundPosition: '-1753' },
    { name: 'Gambia', prefix: '+220', country: 'GM', height: '14', backgroundPosition: '-1951' },
    { name: 'Georgia', prefix: '+995', country: 'GE', height: '14', backgroundPosition: '-1819' },
    { name: 'Ghana', prefix: '+233', country: 'GH', height: '14', backgroundPosition: '-1885' },
    { name: 'Gibraltar', prefix: '+350', country: 'GI', height: '10', backgroundPosition: '-1907' },
    { name: 'Greece', prefix: '+30', country: 'GR', height: '14', backgroundPosition: '-2039' },
    { name: 'Greenland', prefix: '+299', country: 'GL', height: '14', backgroundPosition: '-1929' },
    { name: 'Grenada', prefix: '+1473', country: 'GD', height: '12', backgroundPosition: '-1797' },
    { name: 'Guadeloupe', prefix: '+590', country: 'GP', height: '14', backgroundPosition: '-1995' },
    { name: 'Guam', prefix: '+1671', country: 'GU', height: '11', backgroundPosition: '-2105' },
    { name: 'Guatemala', prefix: '+502', country: 'GT', height: '13', backgroundPosition: '-2083' },
    { name: 'Guernsey', prefix: '+44', country: 'GG', height: '14', backgroundPosition: '-1863' },
    { name: 'Guinea', prefix: '+224', country: 'GN', height: '14', backgroundPosition: '-1973' },
    { name: 'Guinea-Bissau', prefix: '+245', country: 'GW', height: '10', backgroundPosition: '-2127' },
    { name: 'Guyana', prefix: '+592', country: 'GY', height: '12', backgroundPosition: '-2149' },
    { name: 'Haiti', prefix: '+509', country: 'HT', height: '12', backgroundPosition: '-2259' },
    {
        name: 'Heard Island and Mcdonald Islands',
        prefix: '+0',
        country: 'HM',
        height: '10',
        backgroundPosition: '-2193'
    },
    { name: 'Holy See (Vatican City State)', prefix: '+379', country: 'VA', height: '15', backgroundPosition: '-5329' },
    { name: 'Honduras', prefix: '+504', country: 'HN', height: '10', backgroundPosition: '-2215' },
    { name: 'Hong Kong', prefix: '+852', country: 'HK', height: '14', backgroundPosition: '-2171' },
    { name: 'Hungary', prefix: '+36', country: 'HU', height: '10', backgroundPosition: '-2281' },
    { name: 'Iceland', prefix: '+354', country: 'IS', height: '15', backgroundPosition: '-2501' },
    { name: 'India', prefix: '+91', country: 'IN', height: '14', backgroundPosition: '-2413' },
    { name: 'Indonesia', prefix: '+62', country: 'ID', height: '14', backgroundPosition: '-2325' },
    {
        name: 'Iran, Islamic Republic of Persian Gulf',
        prefix: '+98',
        country: 'IR',
        height: '12',
        backgroundPosition: '-2479'
    },
    { name: 'Iraq', prefix: '+964', country: 'IQ', height: '14', backgroundPosition: '-2457' },
    { name: 'Ireland', prefix: '+353', country: 'IE', height: '10', backgroundPosition: '-2347' },
    { name: 'Isle of Man', prefix: '+44', country: 'IM', height: '10', backgroundPosition: '-2391' },
    { name: 'Israel', prefix: '+972', country: 'IL', height: '15', backgroundPosition: '-2369' },
    { name: 'Italy', prefix: '+39', country: 'IT', height: '14', backgroundPosition: '-2523' },
    { name: 'Jamaica', prefix: '+1876', country: 'JM', height: '10', backgroundPosition: '-2567' },
    { name: 'Japan', prefix: '+81', country: 'JP', height: '14', backgroundPosition: '-2611' },
    { name: 'Jersey', prefix: '+44', country: 'JE', height: '12', backgroundPosition: '-2545' },
    { name: 'Jordan', prefix: '+962', country: 'JO', height: '10', backgroundPosition: '-2589' },
    { name: 'Kazakhstan', prefix: '+7', country: 'KZ', height: '10', backgroundPosition: '-2853' },
    { name: 'Kenya', prefix: '+254', country: 'KE', height: '14', backgroundPosition: '-2633' },
    { name: 'Kiribati', prefix: '+686', country: 'KI', height: '10', backgroundPosition: '-2699' },
    {
        name: "Korea, Democratic People's Republic of Korea",
        prefix: '+850',
        country: 'KP',
        height: '10',
        backgroundPosition: '-2765'
    },
    { name: 'Korea, Republic of South Korea', prefix: '+82', country: 'KR', height: '14', backgroundPosition: '-2787' },
    { name: 'Kosovo', prefix: '+383', country: 'XK', height: '15', backgroundPosition: '-5522' },
    { name: 'Kuwait', prefix: '+965', country: 'KW', height: '10', backgroundPosition: '-2809' },
    { name: 'Kyrgyzstan', prefix: '+996', country: 'KG', height: '12', backgroundPosition: '-2655' },
    { name: 'Laos', prefix: '+856', country: 'LA', height: '14', backgroundPosition: '-2875' },
    { name: 'Latvia', prefix: '+371', country: 'LV', height: '10', backgroundPosition: '-3073' },
    { name: 'Lebanon', prefix: '+961', country: 'LB', height: '14', backgroundPosition: '-2897' },
    { name: 'Lesotho', prefix: '+266', country: 'LS', height: '14', backgroundPosition: '-3007' },
    { name: 'Liberia', prefix: '+231', country: 'LR', height: '11', backgroundPosition: '-2985' },
    { name: 'Libyan Arab Jamahiriya', prefix: '+218', country: 'LY', height: '10', backgroundPosition: '-3095' },
    { name: 'Liechtenstein', prefix: '+423', country: 'LI', height: '12', backgroundPosition: '-2941' },
    { name: 'Lithuania', prefix: '+370', country: 'LT', height: '12', backgroundPosition: '-3029' },
    { name: 'Luxembourg', prefix: '+352', country: 'LU', height: '12', backgroundPosition: '-3051' },
    { name: 'Macao', prefix: '+853', country: 'MO', height: '14', backgroundPosition: '-3358' },
    { name: 'Macedonia', prefix: '+389', country: 'MK', height: '10', backgroundPosition: '-3270' },
    { name: 'Madagascar', prefix: '+261', country: 'MG', height: '14', backgroundPosition: '-3226' },
    { name: 'Malawi', prefix: '+265', country: 'MW', height: '14', backgroundPosition: '-3534' },
    { name: 'Malaysia', prefix: '+60', country: 'MY', height: '10', backgroundPosition: '-3578' },
    { name: 'Maldives', prefix: '+960', country: 'MV', height: '14', backgroundPosition: '-3512' },
    { name: 'Mali', prefix: '+223', country: 'ML', height: '14', backgroundPosition: '-3292' },
    { name: 'Malta', prefix: '+356', country: 'MT', height: '14', backgroundPosition: '-3468' },
    { name: 'Marshall Islands', prefix: '+692', country: 'MH', height: '11', backgroundPosition: '-3248' },
    { name: 'Martinique', prefix: '+596', country: 'MQ', height: '14', backgroundPosition: '-3402' },
    { name: 'Mauritania', prefix: '+222', country: 'MR', height: '14', backgroundPosition: '-3424' },
    { name: 'Mauritius', prefix: '+230', country: 'MU', height: '14', backgroundPosition: '-3490' },
    { name: 'Mayotte', prefix: '+262', country: 'YT', height: '14', backgroundPosition: '-5566' },
    { name: 'Mexico', prefix: '+52', country: 'MX', height: '12', backgroundPosition: '-3556' },
    {
        name: 'Micronesia, Federated States of Micronesia',
        prefix: '+691',
        country: 'FM',
        height: '11',
        backgroundPosition: '-1687'
    },
    { name: 'Moldova', prefix: '+373', country: 'MD', height: '10', backgroundPosition: '-3160' },
    { name: 'Monaco', prefix: '+377', country: 'MC', height: '15', backgroundPosition: '-3139' },
    { name: 'Mongolia', prefix: '+976', country: 'MN', height: '10', backgroundPosition: '-3336' },
    { name: 'Montenegro', prefix: '+382', country: 'ME', height: '10', backgroundPosition: '-3182' },
    { name: 'Montserrat', prefix: '+1664', country: 'MS', height: '10', backgroundPosition: '-3446' },
    { name: 'Morocco', prefix: '+212', country: 'MA', height: '14', backgroundPosition: '-3117' },
    { name: 'Mozambique', prefix: '+258', country: 'MZ', height: '14', backgroundPosition: '-3600' },
    { name: 'Myanmar', prefix: '+95', country: 'MM', height: '14', backgroundPosition: '-3314' },
    { name: 'Namibia', prefix: '+264', country: 'NA', height: '14', backgroundPosition: '-3622' },
    { name: 'Nauru', prefix: '+674', country: 'NR', height: '10', backgroundPosition: '-3811' },
    { name: 'Nepal', prefix: '+977', country: 'NP', height: '15', backgroundPosition: '-3796' },
    { name: 'Netherlands', prefix: '+31', country: 'NL', height: '14', backgroundPosition: '-3752' },
    { name: 'New Caledonia', prefix: '+687', country: 'NC', height: '10', backgroundPosition: '-3644' },
    { name: 'New Zealand', prefix: '+64', country: 'NZ', height: '10', backgroundPosition: '-3855' },
    { name: 'Nicaragua', prefix: '+505', country: 'NI', height: '12', backgroundPosition: '-3730' },
    { name: 'Niger', prefix: '+227', country: 'NE', height: '15', backgroundPosition: '-3666' },
    { name: 'Nigeria', prefix: '+234', country: 'NG', height: '10', backgroundPosition: '-3708' },
    { name: 'Niue', prefix: '+683', country: 'NU', height: '10', backgroundPosition: '-3833' },
    { name: 'Norfolk Island', prefix: '+672', country: 'NF', height: '10', backgroundPosition: '-3686' },
    { name: 'Northern Mariana Islands', prefix: '+1670', country: 'MP', height: '10', backgroundPosition: '-3380' },
    { name: 'Norway', prefix: '+47', country: 'NO', height: '15', backgroundPosition: '-3774' },
    { name: 'Oman', prefix: '+968', country: 'OM', height: '10', backgroundPosition: '-3877' },
    { name: 'Pakistan', prefix: '+92', country: 'PK', height: '14', backgroundPosition: '-4009' },
    { name: 'Palau', prefix: '+680', country: 'PW', height: '13', backgroundPosition: '-4163' },
    {
        name: 'Palestinian Territory',
        prefix: '+970',
        country: 'PS',
        height: '10',
        backgroundPosition: '-4119'
    },
    { name: 'Panama', prefix: '+507', country: 'PA', height: '14', backgroundPosition: '-3899' },
    { name: 'Papua New Guinea', prefix: '+675', country: 'PG', height: '15', backgroundPosition: '-3965' },
    { name: 'Paraguay', prefix: '+595', country: 'PY', height: '11', backgroundPosition: '-4185' },
    { name: 'Peru', prefix: '+51', country: 'PE', height: '14', backgroundPosition: '-3921' },
    { name: 'Philippines', prefix: '+63', country: 'PH', height: '10', backgroundPosition: '-3987' },
    { name: 'Pitcairn', prefix: '+64', country: 'PN', height: '10', backgroundPosition: '-4075' },
    { name: 'Poland', prefix: '+48', country: 'PL', height: '13', backgroundPosition: '-4031' },
    { name: 'Portugal', prefix: '+351', country: 'PT', height: '14', backgroundPosition: '-4141' },
    { name: 'Puerto Rico', prefix: '+1939', country: 'PR', height: '14', backgroundPosition: '-4097' },
    { name: 'Qatar', prefix: '+974', country: 'QA', height: '8', backgroundPosition: '-4207' },
    { name: 'Romania', prefix: '+40', country: 'RO', height: '14', backgroundPosition: '-4251' },
    { name: 'Russia', prefix: '+7', country: 'RU', height: '14', backgroundPosition: '-4295' },
    { name: 'Rwanda', prefix: '+250', country: 'RW', height: '14', backgroundPosition: '-4317' },
    { name: 'Reunion', prefix: '+262', country: 'RE', height: '14', backgroundPosition: '-4229' },
    { name: 'Saint Barthelemy', prefix: '+590', country: 'BL', height: '14', backgroundPosition: '-570' },
    {
        name: 'Saint Helena, Ascension and Tristan Da Cunha',
        prefix: '+290',
        country: 'SH',
        height: '10',
        backgroundPosition: '-4471'
    },
    { name: 'Saint Kitts and Nevis', prefix: '+1869', country: 'KN', height: '14', backgroundPosition: '-2743' },
    { name: 'Saint Lucia', prefix: '+1758', country: 'LC', height: '10', backgroundPosition: '-2919' },
    { name: 'Saint Martin', prefix: '+590', country: 'MF', height: '14', backgroundPosition: '-3204' },
    { name: 'Saint Pierre and Miquelon', prefix: '+508', country: 'PM', height: '14', backgroundPosition: '-4053' },
    {
        name: 'Saint Vincent and the Grenadines',
        prefix: '+1784',
        country: 'VC',
        height: '14',
        backgroundPosition: '-5346'
    },
    { name: 'Samoa', prefix: '+685', country: 'WS', height: '10', backgroundPosition: '-5500' },
    { name: 'San Marino', prefix: '+378', country: 'SM', height: '15', backgroundPosition: '-4581' },
    { name: 'Sao Tome and Principe', prefix: '+239', country: 'ST', height: '10', backgroundPosition: '-4691' },
    { name: 'Saudi Arabia', prefix: '+966', country: 'SA', height: '14', backgroundPosition: '-4339' },
    { name: 'Senegal', prefix: '+221', country: 'SN', height: '14', backgroundPosition: '-4603' },
    { name: 'Serbia', prefix: '+381', country: 'RS', height: '14', backgroundPosition: '-4273' },
    { name: 'Seychelles', prefix: '+248', country: 'SC', height: '10', backgroundPosition: '-4383' },
    { name: 'Sierra Leone', prefix: '+232', country: 'SL', height: '14', backgroundPosition: '-4559' },
    { name: 'Singapore', prefix: '+65', country: 'SG', height: '14', backgroundPosition: '-4449' },
    { name: 'Slovakia', prefix: '+421', country: 'SK', height: '14', backgroundPosition: '-4537' },
    { name: 'Slovenia', prefix: '+386', country: 'SI', height: '10', backgroundPosition: '-4493' },
    { name: 'Solomon Islands', prefix: '+677', country: 'SB', height: '10', backgroundPosition: '-4361' },
    { name: 'Somalia', prefix: '+252', country: 'SO', height: '14', backgroundPosition: '-4625' },
    { name: 'South Africa', prefix: '+27', country: 'ZA', height: '14', backgroundPosition: '-5588' },
    { name: 'South Sudan', prefix: '+211', country: 'SS', height: '10', backgroundPosition: '-4669' },
    {
        name: 'South Georgia and the South Sandwich Islands',
        prefix: '+500',
        country: 'GS',
        height: '10',
        backgroundPosition: '-2061'
    },
    { name: 'Spain', prefix: '+34', country: 'ES', height: '14', backgroundPosition: '-1555' },
    { name: 'Sri Lanka', prefix: '+94', country: 'LK', height: '10', backgroundPosition: '-2963' },
    { name: 'Sudan', prefix: '+249', country: 'SD', height: '10', backgroundPosition: '-4405' },
    { name: 'Suriname', prefix: '+597', country: 'SR', height: '14', backgroundPosition: '-4647' },
    { name: 'Svalbard and Jan Mayen', prefix: '+47', country: 'SJ', height: '15', backgroundPosition: '-4515' },
    { name: 'Swaziland', prefix: '+268', country: 'SZ', height: '14', backgroundPosition: '-4779' },
    { name: 'Sweden', prefix: '+46', country: 'SE', height: '13', backgroundPosition: '-4427' },
    { name: 'Switzerland', prefix: '+41', country: 'CH', height: '15', backgroundPosition: '-944' },
    { name: 'Syrian Arab Republic', prefix: '+963', country: 'SY', height: '14', backgroundPosition: '-4757' },
    { name: 'Taiwan', prefix: '+886', country: 'TW', height: '14', backgroundPosition: '-5131' },
    { name: 'Tajikistan', prefix: '+992', country: 'TJ', height: '10', backgroundPosition: '-4933' },
    {
        name: 'Tanzania, United Republic of Tanzania',
        prefix: '+255',
        country: 'TZ',
        height: '14',
        backgroundPosition: '-5153'
    },
    { name: 'Thailand', prefix: '+66', country: 'TH', height: '14', backgroundPosition: '-4911' },
    { name: 'Timor-Leste', prefix: '+670', country: 'TL', height: '10', backgroundPosition: '-4977' },
    { name: 'Togo', prefix: '+228', country: 'TG', height: '13', backgroundPosition: '-4889' },
    { name: 'Tokelau', prefix: '+690', country: 'TK', height: '10', backgroundPosition: '-4955' },
    { name: 'Tonga', prefix: '+676', country: 'TO', height: '10', backgroundPosition: '-5043' },
    { name: 'Trinidad and Tobago', prefix: '+1868', country: 'TT', height: '12', backgroundPosition: '-5087' },
    { name: 'Tunisia', prefix: '+216', country: 'TN', height: '14', backgroundPosition: '-5021' },
    { name: 'Turkey', prefix: '+90', country: 'TR', height: '14', backgroundPosition: '-5065' },
    { name: 'Turkmenistan', prefix: '+993', country: 'TM', height: '14', backgroundPosition: '-4999' },
    { name: 'Turks and Caicos Islands', prefix: '+1649', country: 'TC', height: '10', backgroundPosition: '-4823' },
    { name: 'Tuvalu', prefix: '+688', country: 'TV', height: '10', backgroundPosition: '-5109' },
    { name: 'Uganda', prefix: '+256', country: 'UG', height: '14', backgroundPosition: '-5197' },
    { name: 'Ukraine', prefix: '+380', country: 'UA', height: '14', backgroundPosition: '-5175' },
    { name: 'United Arab Emirates', prefix: '+971', country: 'AE', height: '10', backgroundPosition: '-44' },
    { name: 'United States', prefix: '+1', country: 'US', height: '11', backgroundPosition: '-5263' },
    { name: 'Uruguay', prefix: '+598', country: 'UY', height: '14', backgroundPosition: '-5285' },
    { name: 'Uzbekistan', prefix: '+998', country: 'UZ', height: '10', backgroundPosition: '-5307' },
    { name: 'Vanuatu', prefix: '+678', country: 'VU', height: '12', backgroundPosition: '-5456' },
    {
        name: 'Venezuela, Bolivarian Republic of Venezuela',
        prefix: '+58',
        country: 'VE',
        height: '14',
        backgroundPosition: '-5368'
    },
    { name: 'Vietnam', prefix: '+84', country: 'VN', height: '14', backgroundPosition: '-5434' },
    { name: 'Virgin Islands, British', prefix: '+1284', country: 'VG', height: '10', backgroundPosition: '-5390' },
    { name: 'Virgin Islands, U.S.', prefix: '+1340', country: 'VI', height: '14', backgroundPosition: '-5412' },
    { name: 'Wallis and Futuna', prefix: '+681', country: 'WF', height: '14', backgroundPosition: '-5478' },
    { name: 'Yemen', prefix: '+967', country: 'YE', height: '14', backgroundPosition: '-5544' },
    { name: 'Zambia', prefix: '+260', country: 'ZM', height: '14', backgroundPosition: '-5610' },
    { name: 'Zimbabwe', prefix: '+263', country: 'ZW', height: '10', backgroundPosition: '-5632' }
];
