import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ContainerService {
    private _container!: HTMLElement;

    public get container(): HTMLElement {
        return this._container;
    }

    public set container(container: HTMLElement) {
        this._container = container;
    }

    public scrollToTop(): void {
        this._container.scrollTo({ behavior: 'smooth', top: 0 });
    }

    public scrollToBottom(): void {
        this._container.scrollTo({ behavior: 'smooth', top: this._container.scrollHeight });
    }
}
