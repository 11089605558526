<span class="flex h-5 flex-row items-center justify-start font-normal no-underline">
    <mat-icon *ngIf="icon" class="mr-2 shrink-0 !icon-xs" [svgIcon]="icon"></mat-icon>
    <p
        class="m-0 whitespace-nowrap font-normal underline focus:border"
        erSmall
        [ngClass]="{
            'decoration-color-primary': !icon
        }"
    >
        <ng-content></ng-content>
    </p>
</span>
