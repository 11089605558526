import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@edenred/authentication/environments/environment';
import { IViewModel } from '@edenred/core';
import { ITermsOfUse } from '@edenred/core/modules/authentication-management/domain/interfaces';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { IGetTermsOfUsePayload } from '../../domain/interfaces';
import { TermsRepositoryAbstract } from '../../domain/repositories/terms.repository.abstract';
import { GetTermsOfUseExceptionHandler } from './handlers/get-terms-of-use-exception.handler';
import { IGetTermsOfUseQuery, IResponse, ITermsOfUseEntity } from './interfaces';
import { GetTermsOfUseQueryMapper } from './mappers/queries/get-terms-of-use-query.mapper';
import { GetTermsOfUsePresenter } from './presenters/get-terms-of-use.presenter';

@Injectable({
    providedIn: 'root'
})
export class EasyfuelTermsRepository extends TermsRepositoryAbstract {
    private readonly _host: string = environment.apis.flex.host;

    private readonly _httpClient = inject(HttpClient);
    private readonly _getTermsOfUsePresenter = inject(GetTermsOfUsePresenter);
    private readonly _getTermsOfUseExceptionHandler = inject(GetTermsOfUseExceptionHandler);
    private readonly _getTermsOfUseQueryMapper = inject(GetTermsOfUseQueryMapper);

    public get$(payload: IGetTermsOfUsePayload): Observable<IViewModel<ITermsOfUse>> {
        const endpoint = `${this._host}${environment.apis.flex.endpoints.getTermsOfUse}`;

        const query: IGetTermsOfUseQuery = this._getTermsOfUseQueryMapper.map(payload);

        return this._httpClient.get(endpoint).pipe(
            map(response => {
                const data = (response as IResponse<{ countries: ITermsOfUseEntity[] }[]>)
                    .data![0].countries.filter(terms => terms.country_code === query.country_code)
                    .sort((a, b) => {
                        return parseInt(b.tofu_version!, 10) - parseInt(a.tofu_version!, 10);
                    })[0];

                return this._getTermsOfUsePresenter.getViewModel({ data } as IResponse<ITermsOfUseEntity>);
            }),
            catchError((response: HttpErrorResponse) => throwError(() => this._getTermsOfUseExceptionHandler.handle(response)))
        );
    }
}
