import { patterns as dePatterns } from './extras/de';
import { defaultPatterns } from './patterns';

export type TPatternName =
    | 'accountName'
    | 'companyName'
    | 'alphanumeric'
    | 'bic'
    | 'cardPin'
    | 'companyNumber'
    | 'email'
    | 'iban'
    | 'latin1'
    | 'lettersAndNumbers'
    | 'lowerAndUpperCase'
    | 'numbersOnly'
    | 'numbersSlashsAndHyphens'
    | 'phoneNumber'
    | 'plateNumber'
    | 'specialCharacters'
    | 'taxId'
    | 'vat';

export function getPattern(patternName: TPatternName, countryCode: string | null = null): RegExp {
    let patterns = defaultPatterns;
    if (countryCode) {
        switch (countryCode) {
            case 'de':
                patterns = dePatterns;
                break;
            default:
                throw new Error(`country code '${countryCode}' does not exist.`);
        }
    }

    return patterns[patternName];
}

export function validatePattern(input: string, patternName: TPatternName, countryCode: string | null = null): boolean {
    return getPattern(patternName, countryCode)?.test(input) ?? true;
}
