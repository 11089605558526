import { FormControl } from '@angular/forms';

export enum ErrorKey {
    MIN_LENGTH = 'minlength',
    MAX_LENGTH = 'maxlength',
    REQUIRED = 'required',
    INVALID_DATE = 'invalidDate',
    INVALID_PHONE = 'invalidPhone',
    INVALID_IBAN_FORMAT = 'invalidIbanFormat',
    INVALID_BIC_FORMAT = 'invalidBicFormat',
    IBAN_API_ERROR = 'ibanApiError',
    IBAN_VALIDATION_ERROR = 'ibanValidationError',
    INVALID_EMAIL = 'invalidEmail',
    INVALID_VALUE = 'invalidValue',
    EMAIL = 'email',
    MIX_OF_LETTERS_AND_NUMBERS = 'mixOfLettersAndNumbers',
    MIX_OF_LOWER_AND_UPPER_LETTERS = 'mixOfLowerAndUpperLetters',
    CONTAINS_SPECIAL_CHARACTERS = 'containsSpecialCharacters',
    ACCOUNT_NAME = 'accountName',
    COMPANY_NAME = 'companyName',
    ALPHA_NUMERIC = 'AlphaNumeric',
    ONLY_NUMERIC = 'onlyNumeric',
    CARD_PIN = 'cardPin',
    LATIN_1 = 'latin1',
    NON_GERMAN_PLATE_NUMBER = 'nonGermanPlateNumber',
    PLATE_NUMBER = 'plateNumber',
    PLATE_EXISTING = 'plateExisting',
    DRIVER_EXISTING = 'driverExisting',
    USER_EXISTING = 'userExisting',
    EMAIL_DIFFERENT = 'emailDifferent',
    EMAIL_ALREADY_EXIST = 'usernameAlreadyExist',
    PASSWORD_DONT_MATCH = 'passwordDontMatch',
    NO_WHITESPACE = 'noWhitespace',
    INVALID_PHONE_NUMBER = 'invalidPhoneNumber',
    FORMAT_ISSUE = 'formatIssue',
    INVALID_VAT = 'invalidVat',
    INVALID_TAX_ID = 'invalidTaxId',
    CARD_PRINTED_COMPANY_NAME_FORMAT = 'cardPrintedCompanyNameFormat',
    CARD_PRINTED_COMPANY_NAME_LENGTH = 'cardPrintedCompanyNameLength',
    CARD_PRINTED_DRIVER_NAME_FORMAT = 'cardPrintedDriverNameFormat',
    CARD_PRINTED_DRIVER_NAME_LENGTH = 'cardPrintedDriverNameLength',
    CARD_PRINTED_LICENSE_PLATE = 'cardPrintedLicensePlate',
    MIN = 'min',
    MAX = 'max'
}

export const EErrorMessages: Record<ErrorKey, (control: FormControl<unknown>) => IErrorMessagesType> = {
    [ErrorKey.REQUIRED]: (): IErrorMessagesType => ({ key: 'message.field.required', vars: {} }),
    [ErrorKey.MIN_LENGTH]: (control): IErrorMessagesType => ({
        key: 'message.field.characters.minimum',
        vars: { minimum: control.errors ? control.errors.minlength.requiredLength : null }
    }),
    [ErrorKey.MAX_LENGTH]: (control): IErrorMessagesType => ({
        key: 'message.field.characters.maximum',
        vars: { maximum: control.errors ? control.errors.maxlength.requiredLength : null }
    }),
    [ErrorKey.INVALID_DATE]: (): IErrorMessagesType => ({ key: 'message.field.invalid_date', vars: {} }),
    [ErrorKey.INVALID_PHONE]: (): IErrorMessagesType => ({ key: 'message.field.characters.not_allowed', vars: {} }),
    [ErrorKey.INVALID_EMAIL]: (): IErrorMessagesType => ({ key: 'message.field.invalid_email', vars: {} }),
    [ErrorKey.INVALID_VALUE]: (): IErrorMessagesType => ({ key: 'message.field.invalid_value', vars: {} }),
    [ErrorKey.EMAIL]: (): IErrorMessagesType => ({ key: 'message.field.email_format', vars: {} }),
    [ErrorKey.MIX_OF_LETTERS_AND_NUMBERS]: (): IErrorMessagesType => ({
        key: 'message.password.missed_conditions',
        vars: {}
    }),
    [ErrorKey.MIX_OF_LOWER_AND_UPPER_LETTERS]: (): IErrorMessagesType => ({
        key: 'message.password.missed_conditions',
        vars: {}
    }),
    [ErrorKey.CONTAINS_SPECIAL_CHARACTERS]: (): IErrorMessagesType => ({
        key: 'message.password.missed_conditions',
        vars: {}
    }),
    [ErrorKey.ACCOUNT_NAME]: (): IErrorMessagesType => ({ key: 'message.field.invalid_account_name', vars: {} }),
    [ErrorKey.COMPANY_NAME]: (): IErrorMessagesType => ({ key: 'message.field.invalid_company_name', vars: {} }),
    [ErrorKey.ALPHA_NUMERIC]: (): IErrorMessagesType => ({ key: 'message.field.alphanumerical', vars: {} }),
    [ErrorKey.ONLY_NUMERIC]: (): IErrorMessagesType => ({ key: 'message.field.onlynumeric', vars: {} }),
    [ErrorKey.LATIN_1]: (): IErrorMessagesType => ({ key: 'message.field.latin1_characters', vars: {} }),
    [ErrorKey.CARD_PIN]: (): IErrorMessagesType => ({ key: 'message.pin.numerical', vars: {} }),
    [ErrorKey.IBAN_VALIDATION_ERROR]: (): IErrorMessagesType => ({ key: 'message.field.invalid_iban', vars: {} }),
    [ErrorKey.INVALID_IBAN_FORMAT]: (): IErrorMessagesType => ({ key: 'message.field.invalid_iban', vars: {} }),
    [ErrorKey.INVALID_BIC_FORMAT]: (): IErrorMessagesType => ({ key: 'message.field.invalid_bic', vars: {} }),
    [ErrorKey.INVALID_TAX_ID]: (): IErrorMessagesType => ({ key: 'message.field.invalid_tax_id', vars: {} }),
    [ErrorKey.IBAN_API_ERROR]: (): IErrorMessagesType => ({ key: 'message.field.invalid_iban', vars: {} }),
    [ErrorKey.PLATE_NUMBER]: (): IErrorMessagesType => ({ key: 'message.vehicle_plate.invalid', vars: {} }),
    [ErrorKey.NON_GERMAN_PLATE_NUMBER]: (): IErrorMessagesType => ({
        key: 'message.vehicle_plate.non_german_plate',
        vars: {}
    }),
    [ErrorKey.PLATE_EXISTING]: (): IErrorMessagesType => ({ key: 'message.vehicle_plate.already_exists', vars: {} }),
    [ErrorKey.DRIVER_EXISTING]: (): IErrorMessagesType => ({ key: 'message.driver.already_exists', vars: {} }),
    [ErrorKey.USER_EXISTING]: (): IErrorMessagesType => ({ key: 'message.user.already_exists', vars: {} }),
    [ErrorKey.EMAIL_DIFFERENT]: (): IErrorMessagesType => ({
        key: 'message.field.emails_different',
        vars: {}
    }),
    [ErrorKey.EMAIL_ALREADY_EXIST]: (): IErrorMessagesType => ({
        key: 'os.error_message_email_already_exists',
        vars: {}
    }),
    [ErrorKey.PASSWORD_DONT_MATCH]: (): IErrorMessagesType => ({
        key: 'message.values_dont_match',
        vars: { label: 'Passwords' }
    }),
    [ErrorKey.NO_WHITESPACE]: (): IErrorMessagesType => ({
        key: 'message.field.no_whitespace',
        vars: {}
    }),
    [ErrorKey.INVALID_PHONE_NUMBER]: (): IErrorMessagesType => ({
        key: 'message.invalid_phone_number',
        vars: {}
    }),
    [ErrorKey.FORMAT_ISSUE]: (): IErrorMessagesType => ({
        key: 'message.field.format_issue',
        vars: {}
    }),
    [ErrorKey.INVALID_VAT]: (): IErrorMessagesType => ({
        key: 'message.field.invalid_vat_number',
        vars: {}
    }),
    [ErrorKey.CARD_PRINTED_DRIVER_NAME_FORMAT]: (): IErrorMessagesType => ({
        key: 'message.card_printed.invalid_driver_name.format',
        vars: {}
    }),
    [ErrorKey.CARD_PRINTED_DRIVER_NAME_LENGTH]: (): IErrorMessagesType => ({
        key: 'message.card_printed.invalid_driver_name.length',
        vars: {}
    }),
    [ErrorKey.CARD_PRINTED_COMPANY_NAME_FORMAT]: (): IErrorMessagesType => ({
        key: 'message.card_printed.invalid_company_name.format',
        vars: {}
    }),
    [ErrorKey.CARD_PRINTED_COMPANY_NAME_LENGTH]: (): IErrorMessagesType => ({
        key: 'message.card_printed.invalid_company_name.length',
        vars: {}
    }),
    [ErrorKey.CARD_PRINTED_LICENSE_PLATE]: (): IErrorMessagesType => ({
        key: 'message.card_printed.invalid_license_plate',
        vars: {}
    }),
    [ErrorKey.MIN]: (control): IErrorMessagesType => ({
        key: 'message.field.number.min',
        vars: { min: control.errors ? control.errors.min.min : null }
    }),
    [ErrorKey.MAX]: (control): IErrorMessagesType => ({
        key: 'message.field.number.max',
        vars: { max: control.errors ? control.errors.max.max : null }
    })
};

interface IErrorMessagesType {
    key: string;
    vars: { [key: string]: string };
}
