import { InjectionToken } from '@angular/core';

export interface UnleashConfig {
    url: string;
    clientKey: string;
    appName: string;
    environment: string;
}

export const UNLEASH = new InjectionToken<UnleashConfig>('unleash');
