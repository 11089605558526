import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding, withRouterConfig } from '@angular/router';
import { environment } from '@edenred/authentication/environments/environment';
import { ErAlertModule } from '@edenred/components';
import { CoreModule, httpInterceptors } from '@edenred/core';
import { AuthenticationManagementModule } from '@edenred/core/modules/authentication-management/authentication-management.module';
import { StoreModule } from '@edenred/core/modules/store';
import { TranslateModule } from '@edenred-falcon/shared/angular/i18n';

import { appProviders } from '../modules/app/infrastructure/app.providers';
import { appRoutes } from '../modules/app/infrastructure/app.routing';
import { supportedLocalesFactory } from '../modules/app/infrastructure/factories/supported-locales.factory';

export const appConfig: ApplicationConfig = {
    providers: [
        provideHttpClient(withInterceptors(httpInterceptors)),
        importProvidersFrom(
            BrowserAnimationsModule,
            ErAlertModule.forRoot(),
            StoreModule.forRoot(),
            CoreModule.forRoot({
                account: {
                    host: environment.apis.account.host,
                    domains: environment.apis.account.domains,
                    headers: environment.apis.account.headers
                },
                flex: {
                    domains: environment.apis.flex.domains,
                    headers: environment.apis.flex.headers
                },
                mulesoft: {
                    domains: environment.apis.mulesoft.domains,
                    headers: environment.apis.mulesoft.headers
                }
            }),
            TranslateModule.forRoot({
                defaultLanguage: environment.defaultLanguage,
                supportedLocales: supportedLocalesFactory()
            }),
            AuthenticationManagementModule.forRoot({
                hosts: {
                    account: environment.apis.account.host,
                    userManagement: environment.apis.userManagement.host
                },
                endpoints: {
                    ...environment.apis.account.endpoints,
                    ...environment.apis.userManagement.endpoints
                }
            })
        ),
        provideRouter(
            appRoutes,
            withRouterConfig({
                onSameUrlNavigation: 'reload'
            }),
            withComponentInputBinding()
        ),
        ...appProviders
    ]
};
