import { recursiveAssign } from '@edenred/utilities';

import { environment as base } from './environment.base';

// DEV config
export const environment = {
    ...recursiveAssign(base, {
        apis: {
            flex: {
                host: 'https://api.dev.uta.com/bff-client-tool-service/api/v2',
                headers: {
                    'x-client-id': 'core-edg',
                    'x-client-secret': 'kvu2E5G7dj'
                }
            },
            users: {
                host: 'https://my-uta.dev.eu.uta.io/v2'
            },
            userManagement: {
                host: 'https://my-uta.dev.eu.uta.io/v2'
            },
            reCaptcha: {
                /**
                 * @see https://developers.google.com/recaptcha/docs/faq#id-like-to-run-automated-tests-with-recaptcha.-what-should-i-do
                 */
                siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
            }
        },
        errorReporting: {
            sentry: {
                enabled: true,
                environment: 'develop'
            }
        },
        production: false
    })
};
