export const environment = {
    apis: {
        account: {
            host: 'https://client-tool.sbx.eu.uta.io/v2',
            domains: ['uta.io', 'edenred.io'],
            headers: {
                'accept-language': 'de-de',
                'x-client-id': '29353cbc5c604cb4a82ac96399ddeee1',
                'x-client-secret': '5a6fD1B86111405e92036f9775d92358'
            },
            endpoints: {
                activateAccount: '/users/actions/activate-account'
            }
        },
        flex: {
            host: 'https://api.dev.uta.com/bff-client-tool-service/api/v2',
            domains: ['bff-client-tool-service'],
            headers: {
                'x-client-id': 'core-edg',
                'x-client-secret': 'kvu2E5G7dj'
            },
            endpoints: {
                getTermsOfUse: '/subscription/terms'
            }
        },
        mulesoft: {
            domains: ['uta.io', 'edenred.io'],
            headers: {
                'accept-language': 'de-de',
                'x-client-id': 'bd2d16044bd64760999a3ee6f31cb58f',
                'x-client-secret': '677D5527104E4E6a8d0FaD704e21165f'
            }
        },
        userManagement: {
            host: 'https://my-uta.dev.eu.uta.io/v2',
            endpoints: {
                forgetPassword: '/users/actions/request-forget-password',
                resetPassword: '/users/actions/reset-password'
            }
        },
        users: {
            headers: {},
            host: 'https://my-uta.dev.eu.uta.io/v2',
            endpoints: {
                /**
                 * available tenants
                 * • uta-ei   - to find falcon users (default tenant)
                 * • uta-ldap - to find service center users
                 */
                search: '/users/actions/search'
            }
        },
        reCaptcha: {
            siteKey: '6Le2sugiAAAAACR9CD0M4JMsaBUSDy4LS9WQMMdn'
        }
    },
    defaultLanguage: 'en',
    errorReporting: {
        sentry: {
            dsn: 'https://2872c2ad441a46fcb39e6d604da63d83@o1020557.ingest.sentry.io/5986215',
            enabled: false,
            environment: 'local'
        }
    },
    extranet: {
        host: 'https://my.dev.uta.com '
    },
    serviceCenter: {
        host: 'https://sso.eu.edenred.io/connect/authorize?response_type=code&client_id=e8d6c5eab5c64fc38f7f0764c0114083&scope=openid%20uta-service-center-api&redirect_uri=https%3A%2F%2Fwww.uta.com%2Fservicecenter%2Fprod%2Fspr%2FmeinUta-flow&acr_values=tenant%3Auta-legacy'
    },
    supportedLocales: {
        default: ['en-GB', 'de-DE'],
        easyfuel: ['en-GB', 'de-DE', 'nl-NL', 'fr-FR', 'it-IT', 'lt-LT', 'pl-PL', 'ro-RO', 'es-ES']
    }
};
