import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[erFileDragAndDrop]',
    standalone: true
})
export class FileDragAndDropDirective {
    @HostBinding('class.dragging') public dragging: boolean;

    @Output() public fileDropped = new EventEmitter<FileList>();

    // Dragover listener
    @HostListener('dragover', ['$event']) public onDragOver(e: DragEvent): void {
        e.preventDefault();
        e.stopPropagation();

        this.dragging = true;
    }

    // Dragleave listener
    @HostListener('dragleave', ['$event']) public onDragLeave(e: DragEvent): void {
        e.preventDefault();
        e.stopPropagation();

        this.dragging = false;
    }

    // Drop listener
    @HostListener('drop', ['$event']) public ondrop(e: DragEvent): void {
        e.preventDefault();
        e.stopPropagation();

        this.dragging = false;

        const files = e.dataTransfer?.files;

        if ((files?.length ?? 0) > 0) {
            this.fileDropped.emit(files);
        }
    }
}
