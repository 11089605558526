import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateServiceAbstract } from '@edenred-falcon/shared/angular/i18n';
import { combineLatest, map, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export type ErUnitType = 'liter' | 'unit' | 'kilogram' | 'kilowatt_hour' | 'minute' | 'piece';

export interface ErUnitOptions {
    /**
     * If the value true, use translation short version
     * (ShortUnitTranslationKey), otherwise, use translation
     * long version (UnitTranslationKey).
     */
    short: boolean;
    /**
     * Regex pattern with named groups where each group will represent
     * an attribute to be passed to the translation service.
     *
     * ? ie. The following pattern "^(?<attributeName>.*)$" will provide
     * ? an attribute called "attributeName" with a value containing whole
     * ? the value of the query.
     *
     * ! CAUTION: make sure that all required attributes by the translation
     * ! string are present within the regex pattern.
     */
    pattern: string;
}

enum UnitTranslationKey {
    liter = 'general.unit.liter',
    unit = 'general.unit',
    kilogram = 'general.unit.kilogram',
    kilowatt_hour = 'general.unit.kilowatt_hour',
    minute = 'general.unit.minute',
    piece = 'general.unit.piece'
}

enum ShortUnitTranslationKey {
    liter = 'general.unit.liter.short',
    unit = 'general.unit.short',
    kilogram = 'general.unit.kilogram.short',
    kilowatt_hour = 'general.unit.kilowatt_hour.short',
    minute = 'general.unit.minute.short',
    piece = 'general.unit.piece.short'
}

@Pipe({ name: 'erUnit', standalone: true })
export class ErUnitPipe implements PipeTransform {
    private readonly _defaultOptions: ErUnitOptions = {
        short: false,
        pattern: '^-?(?<count>\\d+(\\.\\d+)?)'
    };

    private _options: ErUnitOptions = this._defaultOptions;

    private readonly _inputPattern = /(?<prefix>\D*)?(?<root>\d+(\.\d+)?)?(?<suffix>\D*)?/;

    private readonly _translateService = inject(TranslateServiceAbstract);

    public transform(
        query: string | number | Observable<string | number>,
        unitType: ErUnitType,
        options?: Partial<ErUnitOptions>
    ): Observable<string> {
        this._setOptions(options as Partial<ErUnitOptions>);
        const input$ = query instanceof Observable ? query : of(query);
        const unitKey = this._getUnitKey(unitType);
        const translatedUnit$ = this._translateUnit$(unitKey, input$);

        return combineLatest([input$, translatedUnit$]).pipe(
            map(([input, translatedUnit]) => {
                const { prefix, root, suffix } = this._getInputGroups(input);
                return `${prefix}${Math.abs(+root)}${suffix} ${translatedUnit}`;
            })
        );
    }

    private _setOptions(customOptions: Partial<ErUnitOptions>): void {
        this._options = { ...this._defaultOptions, ...customOptions };
    }

    private _getArgsFrom(query: string | number): Record<string, string> | undefined {
        const regexp = new RegExp(this._options.pattern, 'gim');

        return regexp.exec(query.toString())?.groups;
    }

    private _getInputGroups(input: string | number): Record<'prefix' | 'root' | 'suffix', string> {
        const { prefix = '', root = '', suffix = '' } = this._inputPattern.exec(String(input))?.groups ?? {};
        return { prefix, root, suffix };
    }

    private _getUnitKey(unitName: string): string {
        const key = unitName.toLocaleLowerCase();
        return this._options.short
            ? ShortUnitTranslationKey[key as keyof typeof ShortUnitTranslationKey]
            : UnitTranslationKey[key as keyof typeof UnitTranslationKey];
    }

    private _translateUnit$(key: string, input$: Observable<string | number>): Observable<string> {
        return input$.pipe(
            map(input => this._getArgsFrom(input)),
            switchMap(args => this._translateService.stream$(key, args))
        );
    }
}
