import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import {
    getPattern,
    isCardPrintedCompanyLengthValid,
    isCardPrintedCompanyPatternValid,
    isCardPrintedLicensePlatePatternValid,
    isCardPrintedNameLengthValid,
    isCardPrintedNamePatternValid,
    isEmailPatternValid,
    TPatternName
} from '@edenred-falcon/shared';
import { DateTime } from 'luxon';

import { isVoid } from './is-void';
import { VALIDATION_ERROR } from './validators.config';

export class ErValidators {
    public static readonly date =
        (): ValidatorFn =>
        (control: AbstractControl): ValidationErrors | null =>
            DateTime.fromISO(control.value).isValid ? null : VALIDATION_ERROR.DATE;

    public static readonly requiredField =
        (fieldName: string, validationErrors: ValidationErrors): ValidatorFn =>
        (control: AbstractControl): ValidationErrors | null =>
            control.value && control.value[fieldName] && !isVoid(control.value[fieldName]) ? null : validationErrors;

    public static readonly controlsMustMatch =
        (
            originControlName: string,
            confirmationControlName: string,
            validationErrors: ValidationErrors,
            caseSensitive = true
        ): ValidatorFn =>
        (formGroup): ValidationErrors | null => {
            let originValue = formGroup.get(originControlName)?.value;
            const confirmationControl = formGroup.get(confirmationControlName);
            let confirmationValue = formGroup.get(confirmationControlName)?.value;

            originValue = caseSensitive ? originValue : originValue.toLowerCase();
            confirmationValue = caseSensitive ? confirmationValue : confirmationValue.toLowerCase();

            const error: ValidationErrors | null =
                originValue && confirmationValue && originValue === confirmationValue ? null : validationErrors;

            confirmationControl?.setErrors(error);

            return null;
        };

    public static readonly accountName = ErValidators.regexValidator('accountName', VALIDATION_ERROR.ACCOUNT_NAME);

    public static readonly companyName = ErValidators.regexValidator('companyName', VALIDATION_ERROR.COMPANY_NAME);

    public static readonly alphaNumeric = ErValidators.regexValidator('alphanumeric', VALIDATION_ERROR.ALPHA_NUMERIC);

    public static readonly vat = ErValidators.regexValidator('vat', VALIDATION_ERROR.INVALID_VAT, true, 'de');

    public static readonly taxId =
        (): ValidatorFn =>
        (control: AbstractControl): ValidationErrors | null => {
            const { value } = <{ value: string }>control;
            return isVoid(value) ||
                // Should contains maximum two slash and/or whitespace
                ((value.match(/( |\/)/g) || []).length <= 2 &&
                    // Must not start with a slash or a whitespace
                    (value.match(/^( |\/)/g) || []).length === 0 &&
                    // Must not end with a slash or a whitespace
                    (value.match(/( |\/)$/g) || []).length === 0 &&
                    // Must contain only numeric, slash and whitespace characters.
                    (value.match(/^([\d /]{11,13})$/g) || []).length === 1)
                ? null
                : VALIDATION_ERROR.INVALID_TAX_ID;
        };

    public static readonly mixOfLettersAndNumbers = ErValidators.regexValidator(
        'lettersAndNumbers',
        VALIDATION_ERROR.MIX_OF_LETTERS_AND_NUMBERS,
        false
    );

    public static readonly mixOfLowerAndUpperLetters = ErValidators.regexValidator(
        'lowerAndUpperCase',
        VALIDATION_ERROR.MIX_OF_LOWER_AND_UPPER_LETTERS,
        false
    );

    public static readonly onlyNumeric = ErValidators.regexValidator('numbersOnly', VALIDATION_ERROR.ONLY_NUMERIC);

    public static readonly iban = ErValidators.regexValidator('iban', VALIDATION_ERROR.IBAN, true, 'de');

    public static readonly bic = ErValidators.regexValidator('bic', VALIDATION_ERROR.BIC);

    public static readonly email =
        (): ValidatorFn =>
        (control: AbstractControl): ValidationErrors | null =>
            control.value === null || control.value === '' || isEmailPatternValid(control.value) ? null : VALIDATION_ERROR.EMAIL;

    public static regexValidator(patternName: TPatternName, error: { [key: string]: boolean }, acceptEmptyValue = true, countryCode = '') {
        return (): ValidatorFn =>
            (control: AbstractControl): ValidationErrors | null => {
                const value = `${control.value ?? ''}`;
                const pattern: RegExp = getPattern(patternName, countryCode);
                return (acceptEmptyValue && value === '') || pattern.test(value) ? null : error;
            };
    }

    public static readonly cardPIN = ErValidators.regexValidator('cardPin', VALIDATION_ERROR.CARD_PIN);

    public static readonly latin1 = ErValidators.regexValidator('latin1', VALIDATION_ERROR.LATIN_1);

    public static readonly plateNumber = ErValidators.regexValidator('plateNumber', VALIDATION_ERROR.PLATE_NUMBER, true);

    public static readonly germanPlateNumber = ErValidators.regexValidator(
        'plateNumber',
        VALIDATION_ERROR.NON_GERMAN_PLATE_NUMBER,
        true,
        'de'
    );

    public static readonly eightAlphaNumericalCharactersOnly =
        (): ValidatorFn =>
        (control: AbstractControl): ValidationErrors | null =>
            control.value?.replace(/ /g, '').replace(/-/g, '').length <= 8 ? null : VALIDATION_ERROR.PLATE_NUMBER;

    public static readonly plateAlreadyExist =
        (): ValidatorFn =>
        (control: AbstractControl): ValidationErrors | null =>
            control ? null : VALIDATION_ERROR.PLATE_EXISTING;

    public static readonly driverAlreadyExist =
        (): ValidatorFn =>
        (control: AbstractControl): ValidationErrors | null =>
            control ? null : VALIDATION_ERROR.DRIVER_EXISTING;

    public static readonly userAlreadyExist =
        (): ValidatorFn =>
        (control: AbstractControl): ValidationErrors | null =>
            control ? null : VALIDATION_ERROR.USER_EXISTING;

    public static isInEnum(erEnum: object): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const { value } = control;
            return Object.values(erEnum).includes(value) ? null : VALIDATION_ERROR.IN;
        };
    }

    public static readonly noWhitespace =
        (): ValidatorFn =>
        (control: AbstractControl): ValidationErrors | null =>
            control.value && control.value.trim().replace(/\s/g, '').length > 0 ? null : VALIDATION_ERROR.NO_WHITESPACE;

    public static readonly companyNumber = ErValidators.regexValidator('companyNumber', VALIDATION_ERROR.FORMAT_ISSUE);
    public static readonly onlyNumericSlashAndHyphen = ErValidators.regexValidator(
        'numbersSlashsAndHyphens',
        VALIDATION_ERROR.FORMAT_ISSUE
    );

    public static readonly cardPrintedCompanyNameFormat =
        (): ValidatorFn =>
        (control: AbstractControl): ValidationErrors | null =>
            control.value === null || isCardPrintedCompanyPatternValid(control.value)
                ? null
                : VALIDATION_ERROR.CARD_PRINTED_COMPANY_NAME_FORMAT;

    public static readonly cardPrintedCompanyNameLength =
        (): ValidatorFn =>
        (control: AbstractControl): ValidationErrors | null =>
            control.value === null || isCardPrintedCompanyLengthValid(control.value)
                ? null
                : VALIDATION_ERROR.CARD_PRINTED_COMPANY_NAME_LENGTH;

    public static readonly cardPrintedLicensePlate =
        (): ValidatorFn =>
        (control: AbstractControl): ValidationErrors | null =>
            control.value === null || isCardPrintedLicensePlatePatternValid(control.value)
                ? null
                : VALIDATION_ERROR.CARD_PRINTED_LICENSE_PLATE;

    public static readonly cardPrintedDriverNameFormat =
        (): ValidatorFn =>
        (control: AbstractControl): ValidationErrors | null =>
            control.value === null || isCardPrintedNamePatternValid(control.value)
                ? null
                : VALIDATION_ERROR.CARD_PRINTED_DRIVER_NAME_FORMAT;

    public static readonly cardPrintedDriverNameLength =
        (): ValidatorFn =>
        (control: AbstractControl): ValidationErrors | null =>
            control.value === null || isCardPrintedNameLengthValid(control.value) ? null : VALIDATION_ERROR.CARD_PRINTED_DRIVER_NAME_LENGTH;

    public static readonly containsSpecialCharacters = ErValidators.regexValidator(
        'specialCharacters',
        VALIDATION_ERROR.CONTAINS_SPECIAL_CHARACTERS,
        false
    );
}
