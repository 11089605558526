// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { AfterViewInit, Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GoogleMap } from '@angular/google-maps';
import { combineLatest, EMPTY, merge, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { EPointOfInterestState } from '../../enums';
import { google, IMapDrag, IPointOfInterest, IPointOfInterestEvent, IPosition } from '../../interfaces';
import { MarkerMapper } from '../../mappers/marker.mapper';
import { PointOfInterestMapper } from '../../mappers/point-of-interest.mapper';
import { ApiLoaderService } from '../../services';

@Component({
    selector: 'er-maps',
    templateUrl: './maps.component.html',
    styleUrls: ['./maps.component.css']
})
export class ErMapsComponent implements OnInit, AfterViewInit {
    @ViewChild(GoogleMap, { static: false }) private _googleMap: GoogleMap;

    @Input({ required: true }) public options!: google.maps.MapOptions;
    @Input({ required: true }) public currentPosition$: Observable<IPointOfInterest> = EMPTY;
    @Input({ required: true }) public pointsOfInterest$: Observable<IPointOfInterest[]> = EMPTY;
    @Input({ required: true }) public pointOfInterestChanges$: Observable<IPointOfInterestEvent> = EMPTY;
    @Input({ required: true }) public centerOnPosition$: Observable<IPosition> = EMPTY;
    @Input({ required: true }) public fitPointsOfInterest$: Observable<null> = EMPTY;

    @Output() public pointOfInterestChange: EventEmitter<IPointOfInterestEvent> = new EventEmitter();
    @Output() public mapDrag: EventEmitter<IMapDrag> = new EventEmitter();
    @Output() public mapClick: EventEmitter<google.maps.LatLng> = new EventEmitter();

    public isLoading$: Observable<boolean> = of(false);
    public currentPositionMarker: google.maps.Marker = null;
    public pointOfInterestMarkers: google.maps.Marker[] = [];

    private readonly _destroyRef = inject(DestroyRef);
    private _previousClickedMarker: google.maps.Marker;

    private readonly _apiLoaderService = inject(ApiLoaderService);
    private readonly _markerMapper = inject(MarkerMapper);
    private readonly _pointOfInterestMapper = inject(PointOfInterestMapper);

    public ngOnInit(): void {
        this.isLoading$ = this._apiLoaderService.load$();
    }

    public ngAfterViewInit(): void {
        merge(
            this._refreshCurrentPositionMarker$(),
            this._refreshPointOfInterestMarkers$(),
            this._catchPointOfInterestMarkerChanges$(),
            this._onCenter$(),
            this._onFitPointsOfInterest$()
        )
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe();
    }

    public mapDragend(): void {
        this.mapDrag.emit({ center: this._googleMap.getCenter(), radius: this.radius });
    }

    public get radius(): number {
        let radius;
        const bounds = this._getBounds();
        const center = this._googleMap.getCenter();
        if (bounds && center) {
            const northEast = bounds.getNorthEast();
            radius = google.maps.geometry.spherical.computeDistanceBetween(center, northEast);
        }
        return radius;
    }

    private get _apiLoaded$(): Observable<boolean> {
        return this.isLoading$.pipe(filter(loaded => loaded));
    }

    private _refreshCurrentPositionMarker$(): Observable<google.maps.Marker> {
        return combineLatest([this._apiLoaded$, this.currentPosition$]).pipe(
            map(([, pointsOfInterest]) => this._markerMapper.map(pointsOfInterest)),
            tap(marker => this._renderCurrentPositionMarker(marker))
        );
    }

    private _refreshPointOfInterestMarkers$(): Observable<google.maps.Marker[]> {
        return combineLatest([this._apiLoaded$, this.pointsOfInterest$]).pipe(
            map(([, pointsOfInterest]) => pointsOfInterest.map(poi => this._markerMapper.map(poi))),
            tap(markers => this._renderPointOfInterestMarkers(markers))
        );
    }

    private _catchPointOfInterestMarkerChanges$(): Observable<google.maps.Marker> {
        return combineLatest([this._apiLoaded$, this.pointOfInterestChanges$]).pipe(
            map(([, updatedPointOfInterest]) => this._markerMapper.map(updatedPointOfInterest.pointOfInterest)),
            tap(marker => this._refreshPointOfInterestMarker(marker))
        );
    }

    private _resetPointOfInterestMarkers(): void {
        this.pointOfInterestMarkers.forEach(marker => marker.setMap(null));
        this.pointOfInterestMarkers = [];
        this._previousClickedMarker = null;
    }

    private _renderCurrentPositionMarker(marker: google.maps.Marker): void {
        this.currentPositionMarker?.setMap(null);
        marker.setMap(this._googleMap.googleMap);
        this.currentPositionMarker = marker;
    }

    private _renderPointOfInterestMarkers(markers: google.maps.Marker[]): void {
        this._resetPointOfInterestMarkers();
        markers.forEach(marker => {
            this._initMarkerListeners(marker);
            marker.setMap(this._googleMap.googleMap);
        });
        this.pointOfInterestMarkers.push(...markers);
    }

    private _refreshPointOfInterestMarker(targetMarker: google.maps.Marker): void {
        this.pointOfInterestMarkers
            .find(marker => marker.getPosition().equals(targetMarker.getPosition()))
            ?.setIcon(targetMarker.getIcon());
    }

    private _onCenter$(): Observable<google.maps.LatLng> {
        return combineLatest([this._apiLoaded$, this.centerOnPosition$]).pipe(
            map(([, position]) => new google.maps.LatLng(position.latitude, position.longitude)),
            tap((latLng: google.maps.LatLng) => this._googleMap.googleMap.setCenter(latLng))
        );
    }

    private _onFitPointsOfInterest$(): Observable<void> {
        return combineLatest([this._apiLoaded$, this.fitPointsOfInterest$, this.pointsOfInterest$]).pipe(
            switchMap(() => of(this._getBounds())),
            filter(bounds => !bounds.isEmpty()),
            map(bounds => this._googleMap.fitBounds(bounds))
        );
    }

    private _getBounds(): google.maps.LatLngBounds {
        const bounds = new google.maps.LatLngBounds();
        this.pointOfInterestMarkers.forEach(marker => bounds.extend(marker.getPosition()));
        return bounds;
    }

    private _initMarkerListeners(marker: google.maps.Marker): void {
        marker.addListener('click', (event: google.maps.MapMouseEvent) => this._handleClick(marker, event));
    }

    private _handleClick(marker: google.maps.Marker, event: google.maps.MapMouseEvent): void {
        if (this._previousClickedMarker && !event.latLng?.equals(this._previousClickedMarker.getPosition())) {
            const pointOfInterest = this._pointOfInterestMapper.map(this._previousClickedMarker);
            this.pointOfInterestChange.emit({ pointOfInterest, state: EPointOfInterestState.DEFAULT });
        }
        this._previousClickedMarker = marker;
        const pointOfInterest = this._pointOfInterestMapper.map(marker);
        this.pointOfInterestChange.emit({ pointOfInterest, state: EPointOfInterestState.SELECTED });
    }
}
