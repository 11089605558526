import { Inject, Injectable } from '@angular/core';
import { UnleashClient } from 'unleash-proxy-client';

import { UNLEASH, UnleashConfig } from '../tokens/unleash.token';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService {
    private _unleash: UnleashClient;

    constructor(@Inject(UNLEASH) private _config: UnleashConfig) {
        this._unleash = new UnleashClient({
            url: _config.url,
            clientKey: _config.clientKey,
            appName: _config.appName,
            environment: _config.environment,
            disableRefresh: true
        });

        this._unleash.start();
    }

    public isEnabled(feature: string): boolean {
        return this._unleash.isEnabled(feature);
    }
}
