const colors = require('tailwindcss/colors');
const plugin = require('tailwindcss/plugin');

module.exports = {
    content: ['./assets/**/*.css', './apps/**/*.{css,html,ts}', './libs/**/*.{css,html,ts}'],
    theme: {
        screens: {
            xs: { raw: '(max-height: 568px)' },
            md: '768px',
            xmd: '996px',
            lg: '1280px',
            xl: '1440px'
        },
        fontSize: {
            xs: ['.75rem', '1rem'],
            sm: ['.875rem', '1.125rem'],
            base: ['1rem', '1.5rem'],
            lg: ['1.125rem', '1.5rem'],
            xl: ['1.25rem', '1.75rem'],
            '2xl': ['1.5rem', '2rem'],
            '3xl': ['1.75rem', '2.25rem'],
            '4xl': ['2rem', '2.25rem'],
            '5xl': ['2.25rem', '3rem'],
            '6xl': ['2.5rem', '3rem'],
            '7xl': ['3.25rem', '3.5rem'],
            '8xl': ['3.75rem', '4.125rem']
        },
        colors: {
            red: {
                150: '#C11E24',
                100: '#F72717',
                80: '#F95245',
                60: '#FA7D74',
                40: '#FCA9A2',
                20: '#FDD4D1'
            },
            mint: {
                150: '#14957C',
                100: '#18AF92',
                50: '#98F6DB7F',
                40: '#A7D8CE',
                30: '#BDE2DA',
                20: '#E4FAF5',
                15: '#98F6DB26',
                10: '#E9F5F3'
            },
            blue: {
                100: '#1482C5',
                60: '#76B6F8',
                40: '#A1CDE8',
                20: '#D0E6F3',
                10: '#E7F3FF'
            },
            grey: {
                80: '#1F252E',
                70: '#333E4D',
                60: '#47576B',
                50: '#5C6F8A',
                40: '#94A3B8',
                30: '#B3BDCC',
                20: '#D1D8E0',
                10: '#F7F8F9',
                5: '#F8F8F8'
            },
            error: {
                150: '#AB3E08',
                100: '#DC500A',
                80: '#E3733B',
                60: '#EA966C',
                40: '#F1B99D',
                20: '#F8DCCE'
            },
            success: {
                150: '#5E8D25',
                100: '#79B530',
                80: '#94C459',
                60: '#AFD383',
                40: '#C9E1AC',
                20: '#E4F0D6'
            },
            warning: {
                150: '#A8800B',
                100: '#D8A50E',
                80: '#e0B73E',
                60: '#E8C96E',
                40: '#EFDB9F',
                20: '#F7EDCF'
            },
            info: {
                150: '#0E6290',
                100: '#1383C0',
                80: '#429CCD',
                60: '#71B5D9',
                40: '#A1CD6',
                20: '#D0E6F2'
            },
            white: colors.white,
            transparent: 'rgba(0, 0, 0, 0)',
            current: 'currentColor',
            primary: '#000000'
        },
        spacing: {
            0: '0',
            1: '.25rem',
            2: '.5rem',
            3: '.75rem',
            4: '1rem',
            5: '1.5rem',
            6: '2rem',
            7: '2.5rem',
            8: '3rem',
            9: '3.75rem',
            10: '4.5rem',
            11: '4.75rem',
            20: '8rem',
            30: '13.5rem'
        },
        minHeight: {
            0: '0',
            1: '.25rem',
            2: '.5rem',
            5: '1.5rem',
            7: '2.5rem'
        },
        stroke: theme => ({
            white: theme('colors.white'),
            red: theme('colors.red.100'),
            grey: theme('colors.grey.30')
        }),
        fill: theme => ({
            blue: theme('colors.blue.20'),
            red: theme('colors.red.100'),
            primary: theme('colors.primary'),
            white: theme('colors.white')
        }),
        triangles: {
            // defaults to {}
            up: {
                direction: 'up', // one of 'left', 'right', 'up', 'down', 'left-up', 'left-down', 'right-up', and 'right-down'
                size: '1rem', // defaults to defaultSize
                height: '0.5em', // defaults to half the size; has no effect on the diagonal directions (e.g. 'left-up')
                color: 'currentColor' // defaults to defaultColor
            }
        },
        extend: {
            fontFamily: {
                sans: ['Ubuntu', 'sans-serif'],
                edenred: ['Edenred', 'sans-serif'],
                'material-icon': ['Material Symbols Rounded']
            },
            outline: theme => ({
                'mint-100': [`1px solid ${theme('colors.mint.100')}`, '.125rem']
            }),
            fill: {
                none: 'none',
                current: 'currentColor'
            },
            boxShadow: theme => ({
                focus: `0 0 4px 0 ${theme('colors.grey.20 / 75%')}`,
                1: `0 2px 10px 0 ${theme('colors.primary / 5%')}`,
                2: `0 2px 6px 0 ${theme('colors.primary / 15%')}`,
                3: `0 8px 16px 0 ${theme('colors.primary / 15%')}`,
                '3-aside': `8px 0 16px 0 ${theme('colors.primary / 15%')}`
            }),
            backgroundSize: {
                100: '100%',
                'size-slider-ticks': '10% 0.5rem',
                'size-flags': '5652px 15px'
            },
            backgroundImage: () => ({
                'slider-ticks': `linear-gradient(
                    to right,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0) 50%,
                    rgba(255, 255, 255, 0.7) 50%,
                    rgba(255, 255, 255, 0.7) calc(50% + 2px),
                    rgba(0, 0, 0, 0) 1px,
                    rgba(0, 0, 0, 0) 100%
                )`
            }),
            listStyleType: {
                'lower-alpha': 'lower-alpha'
            },
            minWidth: {
                initial: 'initial'
            },
            maxWidth: {
                initial: 'initial'
            },
            zIndex: {
                1: '1',
                1000: '1000',
                1100: '1100'
            },
            containers: {
                '2xs': '14.75rem'
            }
        }
    },
    plugins: [
        require('@tailwindcss/container-queries'),
        plugin(function ({ addUtilities, theme }) {
            const screens = theme('screens');

            let newUtilities = {
                '.text-fill-color-initial': {
                    '-webkit-text-fill-color': 'initial'
                },
                '.-outline-offset-1': {
                    'outline-offset': '-1px'
                },
                '.-outline-offset-2': {
                    'outline-offset': '-2px'
                },
                '.outline-width-1': {
                    'outline-width': '1px'
                },
                '.outline-width-2': {
                    'outline-width': '2px'
                },
                '.clip-path-none': {
                    'clip-path': "path('')"
                },
                '.decoration-color-mint-100': {
                    'text-decoration-color': theme('colors.mint.100')
                },
                '.decoration-color-mint-150': {
                    'text-decoration-color': theme('colors.mint.150')
                },
                '.icon-xs': {
                    'font-size': '16px',
                    width: '16px',
                    height: '16px'
                },
                '.icon-sm': {
                    'font-size': '20px',
                    width: '20px',
                    height: '20px'
                },
                '.icon-md': {
                    'font-size': '24px',
                    width: '24px',
                    height: '24px'
                },
                '.icon-lg': {
                    'font-size': '28px',
                    width: '28px',
                    height: '28px'
                },
                '.icon-xl': {
                    'font-size': '36px',
                    width: '36px',
                    height: '36px'
                },
                '.bottom-sheet': {
                    'max-width': '100vw !important',
                    bottom: '0',
                    position: 'absolute !important'
                },
                '.bottom-sheet-full': {
                    'max-width': '100vw !important'
                },
                [`@media (min-width: ${screens.md})`]: {
                    '.bottom-sheet': {
                        'max-width': '80vw !important',
                        bottom: 'auto',
                        position: 'static !important'
                    }
                }
            };

            const spacing = theme('spacing');

            Object.keys(spacing).forEach(key => {
                newUtilities = {
                    ...newUtilities,
                    [`.word-spacing-${key}`]: {
                        'word-spacing': spacing[key]
                    }
                };
            });

            addUtilities(newUtilities);
        })
    ]
};
