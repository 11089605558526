import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';

import { ScriptOptions } from '../interfaces/script-options.interfaces';

@Injectable({
    providedIn: 'root'
})
export class DynamicLoadScriptService {
    private _usedSrcs: string[] = [];

    constructor(@Inject(DOCUMENT) private readonly _document: Document) {}

    public load(renderer: Renderer2, scriptOptions: ScriptOptions): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this._usedSrcs.includes(scriptOptions.src)) {
                resolve(true);
                return;
            }

            this._usedSrcs.push(scriptOptions.src);

            const script = renderer.createElement('script');
            script.type = scriptOptions.type;
            script.src = scriptOptions.src;
            script.async = scriptOptions.async;
            script.charset = scriptOptions.charset;

            script.addEventListener(
                'load',
                () => {
                    resolve(true);
                },
                {
                    once: true
                }
            );

            script.addEventListener(
                'error',
                () => {
                    reject(false);
                },
                {
                    once: true
                }
            );

            renderer.appendChild(this._document.body, script);
        });
    }
}
